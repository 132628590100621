<template>
  <div class="advicerFarmList">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست زمین های کشاورز..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div
      class="farmItem"
      v-for="(item, index) in advicerSelectedFarms"
      :key="index"
      @click="getFarmDetail(item.id)"
    >
      <div class="farmInfoBoxItem">
        <h3>نام زمین</h3>
        <h4>{{ item.title }}</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>محصول (نوع محصول)</h3>
        <h4>{{ item.product }} ({{ item.productType }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>نوع کشت (تاریخ کشت)</h3>
        <h4>{{ item.cultivationsTypeTitle }} ({{ item.cultivationsDate }})</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>استان و شهرستان</h3>
        <h4>{{ item.city }}</h4>
      </div>
      <div class="farmInfoBoxItem">
        <h3>بخش و روستا</h3>
        <h4>{{ item.sector }}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      advicerSelectedFarms: [],
      loading: false,
    };
  },
  props: ["advicerId"],
  watch: {
    advicerId: function () {
      this.getadvicerSelectedFarms();
    },
  },
  methods: {
    ...mapActions("connectionAgent", ["AdvicerSelectedFarms"]),
    async getadvicerSelectedFarms() {
      this.loading = true;
      let result = await this.AdvicerSelectedFarms({ advicerId: this.advicerId });
      this.loading = false;
      if (result) {
        this.advicerSelectedFarms = [];
        result.data.forEach((element) => {
          this.advicerSelectedFarms.push({
            id: element.id,
            title: element.title,
            product: element.product,
            productType: element.productType,
            cultivationsTypeTitle: element.cultivationsType,
            cultivationsDate: element.cultivationsDate,
            city: element.city,
            sector: element.sector,
          });
        });
        if (result.data.length > 0) {
          this.getFarmDetail(result.data[0].id);
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "لیست زمین های کشاورز در دسترس نیست<br /><br />",
          type: "error",
        });
      }
    },
    getFarmDetail(item) {
      this.$emit("selectedFarm", item);
    },
  },
  mounted() {
    if (this.advicerId) {
      this.getadvicerSelectedFarms();
    }
  },
};
</script>

<style scoped>
.advicerFarmList {
  height: 80vh;
  width: 26vw;
  overflow-y: auto;
  overflow-x: hidden;
}
.advicerFarmList::-webkit-scrollbar {
  width: 8px;
}
.advicerFarmList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 20px;
}
.advicerFarmList::-webkit-scrollbar-thumb {
  background: #a1a1a1;
  border-radius: 10px;
}
.advicerFarmList::-webkit-scrollbar-thumb:hover {
  background: rgb(83, 83, 83);
}

/* farmDetail */
.farmItem {
  width: 25vw;
  float: right;
  border-radius: 5px;
  background-color: #f3f3f3;
  margin: 5px 4px 5px 5px;
  border: 1px solid #878787;
  cursor: pointer;
}
.farmItem:hover {
  box-shadow: 0px 0px 5px 2px #01df4b;
  border: 1px solid #01df4b;
}
.farmInfoBoxItem {
  float: right;
  width: 99%;
  margin: 1px 3px 0px 0px;
}
.farmInfoBoxItem h3 {
  float: right;
  font-size: 13px;
  width: 38%;
  text-align: center;
  background-color: #e1e1e1;
  height: 30px;
  margin: 2px;
  padding-top: 8px;
  margin-left: 0px;
}
.farmInfoBoxItem h4 {
  float: right;
  font-size: 15px;
  width: 60%;
  background-color: white;
  height: 30px;
  text-align: right;
  margin-bottom: 0px;
  padding-top: 8px;
  margin-top: 2px;
  padding-right: 3px;
  border: 1px solid #dbdbdb;
}
</style>
