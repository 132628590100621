<template>
  <div style="width: 100vw; height: 100vh">
    <div class="allAdvicersList">
      <h5>لیست متخصصان زیر مجموعه شما</h5>
      <advicerList @selectedAdvicer="agentSelectedAdvicer" />
    </div>
    <div class="allAdvicersFarms">
      <h5>لیست زمین های متخصص انتخاب شده</h5>
      <farmList
        :advicerId="advicerIdSelected"
        @selectedFarm="agentSelectedFarm"
      />
    </div>
    <div class="detailBox">
      <farmDetail :farmId="farmIdSelected" />
    </div>
  </div>
</template>

<script>
import advicerList from "./subForm/advicerList.vue";
import farmList from "./subForm/farmList.vue";
import farmDetail from "./subForm/farmDetail.vue";
export default {
  components: {
    advicerList,
    farmList,
    farmDetail,
  },
  data() {
    return {
      advicerIdSelected : null,
      farmIdSelected : null,
    };
  },
  computed: {},
  methods: {
    agentSelectedAdvicer(item) {
      this.advicerIdSelected = item;
    },
    agentSelectedFarm(item) {
      this.farmIdSelected = item;
    },
  },
};
</script>

<style scoped>
.allAdvicersList {
  float: right;
  height: 86vh;
  width: 18vw;
  margin-top: 2vh;
  margin-right: 1vw;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allAdvicersList h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.allAdvicersFarms {
  float: right;
  height: 86vh;
  width: 26vw;
  margin-right: 1vw;
  margin-top: 2vh;
  box-shadow: 0px 0px 3px 1px #3c4b64;
  background-color: #fff;
}
.allAdvicersFarms h5 {
  text-align: center;
  font-size: 1rem;
  padding: 3px;
  color: #3c4b64;
  border-bottom: 1px dashed #bbb;
}
.detailBox {
  float: right;
  height: 86vh;
  width: 37vw;
  margin-right: 10px;
  margin-top: 2vh;
}
</style>
