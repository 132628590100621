<template>
  <div class="advicerInfoBox">
    <vue-element-loading
      :active="loading"
      text="دریافت لیست متخصصان منطقه شما..."
      spinner="bar-fade-scale"
      color="var(--secondary)"
    />
    <div
      class="advicerInfo main-card"
      v-for="(item, index) of advicerList"
      :key="index"
      @click="advicerSelected(item.id)"
    >
      <h4>{{ item.fullName }} ({{ item.createDate }})</h4>
      <hr />
      <div>
        <h6><span class="arrow"></span>{{ item.codeIdentity }}</h6>
        <h6><span class="arrow"></span>{{ item.phone }}</h6>
      </div>
      <div>
        <h6><span class="arrow"></span>{{ item.education }}</h6>
        <h6 style="font-size: 11px">
          <span class="arrow"></span>{{ item.BirthDate }} تاریخ تولد
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      advicerList: [],
      loading: false,
      searchAdvicer: null,
    };
  },
  computed: {},
  methods: {
    ...mapActions("connectionAgent", ["GetAllAgentAdvicers"]),
    async fetchList() {
      this.loading = true;
      let result = await this.GetAllAgentAdvicers({
        searchName: this.searchAdvicer,
      });
      this.loading = false;
      if (result) {
        result.data.forEach((element, index) => {
          this.advicerList.push({
            id: element.id,
            userId: element.userId,
            codeIdentity: element.codeIdentity,
            fullName: element.fullName,
            birthDate: element.BirthDate,
            phone: element.phone,
            education: element.education,
            createDate: element.createDate,
          });
        });
        if(result.data.length > 0){
            this.advicerSelected(result.data[0].id);
        }
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: "خطا در دریافت لیست متخصصان <br /><br />",
          type: "error",
        });
      }
    },
    advicerSelected(item) {
      this.$emit("selectedAdvicer", item);
    },
  },
  mounted() {
    this.fetchList();
  },
  created() {},
};
</script>

<style scoped>
.advicerInfoBox {
  height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.advicerInfo h4 {
  float: right;
  width: 100%;
  font-size: 14px;
  text-align: center;
  margin: 0px;
  margin-top: 10px;
}
.advicerInfo hr {
  width: 90%;
  height: 1px;
  margin: 1% 5% 1% 5%;
  background-color: #f1f1f1;
  float: right;
  clear: both;
}
.advicerInfo div {
  float: right;
  width: 100%;
  clear: both;
  margin-top: 10px;
}
.advicerInfo div h6 {
  float: right;
  width: 50%;
  padding-right: 10px;
}
.arrow {
  border-right: 7px solid transparent;
  border-bottom: 8px solid rgb(194, 103, 0);
  float: right;
  margin: 1px 1px 1px 3px;
}
</style>
